"use client"
import dynamic from "next/dynamic"
import { useRouter, usePathname } from "next/navigation"
import { useCallback, useMemo, type FC } from "react"

import styles from "./HeaderNavbar.module.scss"

import { SelectChangeEvent } from "@/components/common/select"
import { Skeleton } from "@/components/common/skeleton"
import type { NavbarProps, NavbarCallToAction } from "@/components/core/navbar"

import { graphqlFetcher } from "@/helpers/api/client/lib/fetcher"
import { create } from "@/helpers/bem"
import { getLanguage } from "@/helpers/getLanguage"
import {
  trackMainNavigationState,
  trackLanguageChange,
  trackCTAAppDownloadRedirect,
  trackPageChange,
} from "@/helpers/tracking"
import { GET_COMPLEMENTARY_PAGE } from "@/queries/global"
import { useLeadForm } from "@/store/leadForm"
import type {
  PageEntity,
  Article,
  ArticleEntity,
  CategoryEntity,
  I18NLocaleEntity,
  ComplementaryPageQuery,
  ComplementaryPageQueryVariables,
} from "@/types/generated/strapi/graphql"
import { type Locale, i18n } from "@/utils/translations/i18n-config"

const Navbar = dynamic(
  () =>
    import("@/components/core/navbar").then(({ Navbar }) => ({
      default: Navbar,
    })),
  { loading: () => <Skeleton height={80} variant="rectangular" /> },
)

const bem = create(styles, "HeaderNavbar")

export type HeaderNavbarProps = {
  locale: Locale
  languages?: I18NLocaleEntity[]
} & Omit<NavbarProps, "languages" | "onChangeLanguage">

export const HeaderNavbar: FC<HeaderNavbarProps> = ({
  locale,
  languages,
  ...props
}) => {
  const router = useRouter()
  const path = usePathname()
  const { updateLeadForm } = useLeadForm()

  const resolveComplementarySlug = useCallback(
    (
      newLocale: Locale,
      type?: "page" | "article" | "category",
      slugs?: ArticleEntity | PageEntity | CategoryEntity,
    ) => {
      const attr = slugs?.attributes
      const defaultLocale = i18n.defaultLocale
      const isDefault = defaultLocale === newLocale
      const filteredLocalizations = (attr?.localizations?.data || []).filter(
        (e: ArticleEntity | PageEntity | CategoryEntity) =>
          e?.attributes?.locale === newLocale,
      )
      switch (type) {
        case "article": {
          const article = attr as Article
          const localizedArticle = filteredLocalizations?.[0]
            ?.attributes as Article
          const defaultArticleSlug = `/${defaultLocale}/blog/${article?.category.data?.attributes?.slug}/${article?.slug}`
          const localizedArticleSlug = `/${newLocale}/blog/${localizedArticle?.category?.data?.attributes?.slug}/${localizedArticle?.slug}`
          return isDefault || !localizedArticle
            ? defaultArticleSlug
            : localizedArticleSlug
        }
        case "category": {
          const defaultCategorySlug = `/${defaultLocale}/blog/${attr?.slug}`
          const localizedCategorySlug = `/${newLocale}/blog/${filteredLocalizations?.[0]?.attributes?.slug}`
          return isDefault || !filteredLocalizations?.[0]
            ? defaultCategorySlug
            : localizedCategorySlug
        }
        case "page": {
          const defaultPageSlug = `/${defaultLocale}/${attr?.slug}`
          const localizedPageSlug = `/${newLocale}/${filteredLocalizations?.[0]?.attributes?.slug}`
          return isDefault || !filteredLocalizations?.[0]
            ? defaultPageSlug
            : localizedPageSlug
        }
        default: {
          // Static mappings (not controlled by CMS)
          if (locale === newLocale) return
          const currentPath = path.replace(`/${locale}`, "")
          return `/${newLocale}${currentPath}`
        }
      }
    },
    [path, locale],
  )

  const onChangeLanguage = useCallback(
    async (e: SelectChangeEvent<unknown>) => {
      const newLocale = e?.target?.value as Locale
      const splittedPath = path.split("/").filter((e) => e !== "")
      const data = await graphqlFetcher<ComplementaryPageQuery>(
        GET_COMPLEMENTARY_PAGE,
        {
          locale: newLocale,
          slug: splittedPath[splittedPath.length - 1],
        } as ComplementaryPageQueryVariables,
      )
      let redirectTo
      const articleSlugs = data?.articles?.data
      if (articleSlugs && articleSlugs?.length > 0) {
        redirectTo = resolveComplementarySlug(
          newLocale,
          "article",
          articleSlugs?.[0] as ArticleEntity,
        )
      }
      const categorySlugs = data?.categories?.data
      if (categorySlugs && categorySlugs?.length > 0) {
        redirectTo = resolveComplementarySlug(
          newLocale,
          "category",
          categorySlugs?.[0] as CategoryEntity,
        )
      }
      const pageSlugs = data?.pages?.data
      if (pageSlugs && pageSlugs?.length > 0) {
        redirectTo = resolveComplementarySlug(
          newLocale,
          "page",
          pageSlugs?.[0] as PageEntity,
        )
      }
      const newSlug = redirectTo ?? resolveComplementarySlug(newLocale) ?? "/"
      trackLanguageChange(locale, newLocale)
      router.push(newSlug)
    },
    [router, path, locale, resolveComplementarySlug],
  )

  const onChangeMenu = (state: boolean) => trackMainNavigationState(state, path)

  const mappedLanguages = useMemo(
    () =>
      languages?.map((el) => {
        const lang = el?.attributes
        const code = lang?.code ?? ""
        return {
          label: lang?.name ?? "",
          value: code,
          icon: () => (
            <i
              className={bem("language__flag")}
              dangerouslySetInnerHTML={{
                __html: getLanguage(code).flag as string,
              }}
            />
          ),
        }
      }),
    [languages],
  )
  return (
    <Navbar
      {...props}
      languages={mappedLanguages}
      callToAction={
        {
          ...props.callToAction,
          redirect: undefined,
          onClick: () => {
            // props.callToAction?.onClick?.(e)
            updateLeadForm({
              open: true,
            })
            // TODO: CHANGE TRACKING EVENT
            trackCTAAppDownloadRedirect("navigation", path)
          },
        } as NavbarCallToAction
      }
      onChangeLanguage={onChangeLanguage}
      onChangeMenu={onChangeMenu}
      onClickMenuLink={(name, slug) =>
        trackPageChange("mainnavigation", path, slug ?? "", name)
      }
    />
  )
}
