"use client"
import { useTheme } from "@mui/material/styles"
import { type FC, isValidElement, useCallback } from "react"

import { Animated } from "../animated"
import { Button } from "../button"
import { Card } from "../card"
import { Grid, GridRow } from "../grid"
import { Headline } from "../headline"
import { NextImage, type NextImageProps } from "../image"
import { RichText } from "../rich-text"

import type { PromoBannerProps } from "./PromoBanner.model"
import styles from "./PromoBanner.module.scss"

import { create } from "@/helpers/bem"

const bem = create(styles, "PromoBanner")

export const PromoBanner: FC<PromoBannerProps> = ({
  id,
  locale = "en",
  variant,
  className,
  cardClassName,
  title,
  content,
  image,
  imageDark,
  buttonProps,
  ...props
}) => {
  const theme = useTheme()
  const darkMode = theme.palette.mode === "dark"
  const themeModifier = {
    [theme.palette.mode]: true,
  }
  const renderImage = useCallback(() => {
    if (isValidElement(image)) {
      return image
    }
    let imageSrc = image
    if (darkMode && imageDark?.src) {
      imageSrc = imageDark
    }
    return (
      <NextImage
        {...(imageSrc as NextImageProps)}
        fill
        className={bem("image")}
        sizes="(max-width: 959px) 100vw, 50vw"
      />
    )
  }, [darkMode, image, imageDark])
  return (
    <div className={bem(undefined, undefined, className)} id={id}>
      <Animated animation="bottom-top" className={bem("animation")}>
        <Card
          variant={variant ?? "primary"}
          {...props}
          className={bem("card", undefined, cardClassName)}
          contentClassName={bem("card__content", themeModifier)}
          backgroundProps={{
            sizes: "100vw",
          }}
        >
          <Grid className={bem("row")}>
            <GridRow className={bem("col")} md={6} xs={12}>
              <Headline size="xl" type="h2">
                {title}
              </Headline>
              {content && (
                <RichText className={bem("content", themeModifier)}>
                  {content}
                </RichText>
              )}
              <Button
                {...buttonProps}
                className={bem("button", undefined, buttonProps?.className)}
                title={buttonProps?.title ?? "Let's talk"}
              />
            </GridRow>
            <GridRow
              alignItems="flex-end"
              className={bem("col")}
              md={6}
              xs={12}
            >
              {image && renderImage()}
            </GridRow>
          </Grid>
        </Card>
      </Animated>
    </div>
  )
}
