"use client"
import { type ReactNode, memo, useCallback } from "react"

import { Link, type LinkProps } from "@/components/common/link"

export type BreadCrumbLinkProps = LinkProps & {
  children: ReactNode
}

export const BreadCrumbLink = memo(
  ({ href, variant, children }: BreadCrumbLinkProps) => {
    const handleClick = useCallback(async () => {
      const { trackPageChange } = await import("@/helpers/tracking")
      trackPageChange("breadcrumb", children?.toString() ?? "", href)
    }, [href, children])

    return (
      <Link
        href={href}
        itemProp="item"
        variant={variant === "primary" ? "inherit" : variant}
        onClick={handleClick}
      >
        <span itemProp="name">{children}</span>
      </Link>
    )
  },
)

BreadCrumbLink.displayName = "BreadCrumbLink"
