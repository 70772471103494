"use client"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import { useTheme } from "@mui/material/styles"
import styled from "@mui/material/styles/styled"
import MUISwitch, {
  type SwitchProps as MUISwitchProps,
} from "@mui/material/Switch"
import type { FC } from "react"

import { Icon, IconName } from "../icon"

import { SwitchProps } from "./Switch.model"

import styles from "./Switch.module.scss"

import { create } from "@/helpers/bem"

const bem = create(styles, "Switch")

export const Switch: FC<SwitchProps> = ({
  variant = "primary",
  label,
  icon,
  checked,
  checkedIcon,
  ...props
}) => {
  const theme = useTheme()
  const CustomSwitch = styled((props: MUISwitchProps) => (
    <MUISwitch
      disableRipple
      focusVisibleClassName=".Mui-focusVisible"
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" && variant === "inherit"
              ? theme.palette.grey[600]
              : (theme.palette?.[variant]?.main ?? theme.palette.primary.main),
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: theme.palette?.[variant]?.main ?? theme.palette.primary.main,
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.common.white,
        opacity: 0.8,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.grey[200],
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }))
  const renderIcon = (name?: IconName) => {
    const modifier = {
      [variant]: !!checked,
      [`${theme.palette.mode}--${variant}`]: !!checked,
    }
    return (
      <span className={bem("icon__wrapper")}>
        <Icon className={bem("icon", modifier)} name={name} />
      </span>
    )
  }
  const renderControl = () => {
    const controlProps = {
      sx: { m: 1 },
    }
    if (icon && checkedIcon) {
      return (
        <CustomSwitch
          {...controlProps}
          checkedIcon={renderIcon(checkedIcon)}
          icon={renderIcon(icon)}
        />
      )
    }
    return <CustomSwitch {...controlProps} />
  }
  const modifier = {
    [theme.palette.mode]: true,
  }
  return (
    <FormGroup className={bem()}>
      <FormControlLabel
        checked={checked}
        className={bem("control", modifier, props?.classes?.label)}
        control={renderControl()}
        label={label}
        {...props}
      />
    </FormGroup>
  )
}
