"use client"

import { useReportWebVitals } from "next/web-vitals"

export const WebVitals = () => {
  useReportWebVitals((metric) => {
    // TODO: Implement logic in production to send metrics to GA
    console.log(metric)
  })
  return null
}
