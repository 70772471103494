"use client"
import { useTheme } from "@mui/material/styles"
import { useEffect, useState, useCallback, useMemo, type FC } from "react"

import type { CookieBannerProps } from "./CookieBanner.model"

import { Link } from "@/components/common/link"
import {
  CookieBanner as CoreCookieBanner,
  type CookieBannerSettingsCookie,
} from "@/components/core/cookie-banner"

import {
  getCookieConfig,
  COOKIE_CONFIG_TYPES,
  type CookieConfig,
  type CookieConfigCookie,
  type CookieConfigType,
  type CookieConfigTypes,
  type CookieConfigUsedBy,
} from "@/config/cookies"
import { useCookieContext, type CookieSettingCookie } from "@/context/cookies"
import { resolveDarkModeAsset } from "@/helpers/theme"
import type { UploadFileEntityResponse } from "@/types/generated/strapi/graphql"

const I18N_BASE = "pages.provider.cookies"

export const CookieBanner: FC<CookieBannerProps> = ({
  appURL = "",
  logo,
  tracking,
  translations,
}) => {
  const {
    cookieSettings = [],
    cookieDialogOpen,
    handleChangeCookieDialog,
    saveCookieSettings,
  } = useCookieContext()
  const theme = useTheme()
  const isDark = theme.palette.mode === "dark"
  const [cookieConfig, setCookieConfig] = useState<CookieConfig>([])

  useEffect(() => {
    const config = getCookieConfig(tracking)
    setCookieConfig(config)
  }, [tracking])

  const getHost = useCallback(
    (host: string, usedBy?: CookieConfigUsedBy) =>
      usedBy === "third" ? host : appURL?.replace(/^https?:\/\//, ""),
    [appURL],
  )

  const mappedCookies = useMemo(
    () =>
      COOKIE_CONFIG_TYPES.map((type: CookieConfigTypes) => {
        const setting = cookieSettings?.filter(
          (e) => e.type === type.name,
        )?.[0] as CookieSettingCookie
        const t = translations?.[I18N_BASE]?.settings?.types?.[type?.name]
        return {
          id: type.name,
          required: type.required,
          checked: setting?.enabled ?? type?.checked,
          title: t?.title,
          content: t?.content,
          details: cookieConfig
            .filter((e: CookieConfigCookie) => e.type === type.name)
            .map(({ name, host, usedBy }: any) => ({
              name,
              host: getHost(host, usedBy),
              usedBy,
            })),
        } as CookieBannerSettingsCookie
      }),
    [translations, cookieSettings, cookieConfig, getHost],
  ) as unknown as CookieBannerSettingsCookie[]

  const handleSaveCookieSettings = useCallback(
    async (
      settings?: CookieBannerSettingsCookie[] | null,
      action?: "granted" | "denied",
    ) => {
      switch (action) {
        case "granted":
          await saveCookieSettings(
            mappedCookies.map((cookie) => ({
              type: cookie.id as CookieConfigType,
              enabled: true,
            })),
          )
          break
        case "denied":
          await saveCookieSettings(
            mappedCookies.map((cookie) => ({
              type: cookie.id as CookieConfigType,
              enabled: !!cookie?.required,
            })),
          )
          break
        default:
          await saveCookieSettings(
            (settings ?? []).map((cookie) => ({
              type: cookie.id as CookieConfigType,
              enabled: cookie?.required || !!cookie.checked,
            })),
          )
      }
    },
    [mappedCookies, saveCookieSettings],
  )
  const logoDefaultAlt = logo?.logo.data?.attributes?.alternativeText as string
  const tSettings = translations?.settings
  return (
    <CoreCookieBanner
      open={cookieDialogOpen}
      acceptButton={{
        title: translations?.accept ?? "Dismiss",
        onClick: (cookies) => handleSaveCookieSettings(cookies, "granted"),
      }}
      content={() => (
        <>
          {translations?.content} {translations?.link1}
          <Link href="#" target="_blank">
            {translations?.link2}
          </Link>
          {translations?.link3}
        </>
      )}
      rejectButton={{
        title: translations?.reject ?? "Reject all",
        onClick: (cookies) => handleSaveCookieSettings(cookies, "denied"),
      }}
      settings={{
        logo: {
          src: resolveDarkModeAsset(
            theme,
            logo?.logo as UploadFileEntityResponse,
            logo?.logoDark,
          ),
          alt: isDark
            ? (logo?.logoDark?.data?.attributes?.alternativeText ??
              logoDefaultAlt)
            : logoDefaultAlt,
        },
        content: tSettings?.content,
        title: tSettings?.title,
        linkText: tSettings?.link ?? "View cookie",
        detailOptions: {
          subTitle: tSettings?.details?.subTitle,
          accordionDefaultTitle: tSettings?.details?.accordion?.defaultTitle,
          backIcon: {
            "aria-label": tSettings?.details?.backIcon?.ariaLabel,
          },
          noDataMsg: tSettings?.details?.noData,
        },
        cookies: mappedCookies,
        acceptButton: {
          title: tSettings?.accept,
        },
        settingsButton: {
          title: tSettings?.save,
        },
      }}
      settingsButton={{
        title: translations?.setting ?? "Cookie settings",
        onClick: (cookies) => handleSaveCookieSettings(cookies),
      }}
      onClose={() => handleChangeCookieDialog(false)}
    />
  )
}
