"use client"
import { type ReactEventHandler, memo } from "react"

import { Button } from "../../common/button"
import { GridRow } from "../../common/grid"

import styles from "./CBButtons.module.scss"
import type { CookieBannerActionButton } from "./CookieBanner.model"

import { create } from "@/helpers/bem"

const bem = create(styles, "CBButtons")

export const CookieBannerButtons = memo(
  ({
    screenSmallerMd,
    settingsButton,
    acceptButton,
    rejectButton,
    onClose,
    setSettingsOpen,
  }: {
    screenSmallerMd: boolean
    settingsButton: CookieBannerActionButton
    acceptButton: CookieBannerActionButton
    rejectButton: CookieBannerActionButton
    onClose: ReactEventHandler<{}>
    setSettingsOpen: (open: boolean) => void
  }) => (
    <GridRow className={bem()} lg={4} md={5} xs={12}>
      <div>
        <Button
          className={bem("button")}
          fullWidth={screenSmallerMd}
          size="small"
          variant="outlined"
          {...settingsButton}
          onClick={() => setSettingsOpen(true)}
        />
      </div>
      <div>
        <span
          className={bem("button__custom")}
          role="button"
          tabIndex={0}
          onClick={(e) => {
            rejectButton.onClick?.()
            onClose(e)
          }}
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              rejectButton.onClick?.()
              onClose(e)
            }
          }}
        >
          {rejectButton.title}
        </span>
      </div>
      <div>
        <Button
          className={bem("button")}
          fullWidth={screenSmallerMd}
          size="medium"
          variant="contained"
          {...acceptButton}
          onClick={(e) => {
            acceptButton?.onClick?.()
            onClose(e)
          }}
        />
      </div>
    </GridRow>
  ),
)
