import MatChip from "@mui/material/Chip"
import type { FC } from "react"

import type { ChipProps } from "./Chip.model"

export const Chip: FC<ChipProps> = ({
  className,
  variant = "filled",
  color = "primary",
  ...props
}) => <MatChip {...props} color={color} variant={variant} />

Chip.displayName = "Chip"
