"use client"
import useMediaQuery from "@mui/material/useMediaQuery"
import { usePathname } from "next/navigation"
import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react"

import { trackDarkModeChange } from "@/helpers/tracking"
import type { Locale } from "@/utils/translations/i18n-config"

let updateCookieTheme: any

if (process.env.STORYBOOK !== "true") {
  updateCookieTheme =
    require("@/app/actions/cookies/updateCookieTheme").updateCookieTheme
} else {
  updateCookieTheme = async (locale: string, theme: "light" | "dark") => {
    console.log(
      `Storybook: Mock updateCookieTheme called with locale: ${locale}, theme: ${theme}`,
    )
  }
}

interface ThemeContextType {
  darkMode: boolean | undefined
  handleDarkMode: () => void
}

const ThemeCtx = createContext<ThemeContextType | undefined>(undefined)

export function ThemeCtxProvider({
  locale,
  children,
}: {
  locale: Locale
  children: ReactNode
}) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)")
  const pathname = usePathname()
  const [darkMode, setDarkMode] = useState<boolean | undefined>(prefersDarkMode)

  const setThemeModeCookie = async (mode: "dark" | "light") => {
    await updateCookieTheme(locale as Locale, mode)
  }

  useEffect(() => {
    const theme = localStorage.getItem("theme")
    if (theme !== undefined) {
      const isDark = theme === "dark"
      setDarkMode(theme === "dark")
      setThemeModeCookie(isDark ? "dark" : "light")
      return
    }
    setDarkMode(prefersDarkMode)
    setThemeModeCookie(prefersDarkMode ? "dark" : "light")
  }, [prefersDarkMode])

  const handleDarkMode = async () => {
    if (darkMode) {
      await setThemeModeCookie("light")
      trackDarkModeChange("dark", "light", pathname)
      localStorage.setItem("theme", "light")
      setDarkMode(false)
    } else {
      await setThemeModeCookie("dark")
      trackDarkModeChange("light", "dark", pathname)
      localStorage.setItem("theme", "dark")
      setDarkMode(true)
    }
  }

  return (
    <ThemeCtx.Provider value={{ darkMode, handleDarkMode }}>
      {children}
    </ThemeCtx.Provider>
  )
}

export function useThemeMode(): ThemeContextType {
  const context = useContext(ThemeCtx)

  if (context === undefined) {
    throw new Error("useThemeMode must be used within a ThemeCtxProvider")
  }

  return context
}
