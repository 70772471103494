"use client"
import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
} from "react"

import { updateCookieSettings } from "@/app/actions/cookies/updateCookieSettings"
import type { CookieSettingCookie as ActionCookieSettingCookie } from "@/app/actions/cookies/updateCookieSettings"
import { updateCookieConsentMode } from "@/helpers/tracking"

import type { CookieConsentPermissions } from "@/helpers/tracking"
import type { Locale } from "@/utils/translations/i18n-config"

export type CookieSettingCookie = ActionCookieSettingCookie

export interface Cookie {
  name: string
  value: string
}

export interface CookieContextType {
  cookieSettings: CookieSettingCookie[] | null
  cookieDialogOpen: boolean
  handleChangeCookieDialog: (e: boolean) => void
  saveCookieSettings: (settings: CookieSettingCookie[] | null) => void
}

const CookieContext = createContext<CookieContextType | undefined>(undefined)

export const useCookieContext = () => {
  const context = useContext(CookieContext)
  if (!context) {
    throw new Error("useCookieContext must be used within a CookieProvider")
  }
  return context
}

export type CookieProviderProps = {
  locale: Locale
  cookies: CookieSettingCookie[]
  children?: ReactNode
}

export const CookieProvider: FC<CookieProviderProps> = ({
  locale,
  cookies,
  children,
}) => {
  const [cookieDialogOpen, setCookieDialogOpen] = useState<boolean>(false)
  const [cookieSettings, setCookieSettings] = useState<
    CookieSettingCookie[] | null
  >(null)

  // Handle cookie dialog
  const handleChangeCookieDialog = (e: boolean) => {
    setCookieDialogOpen(e)
  }

  // Update Google Tag Manager Consent Mode
  const updateConsentMode = (settings: CookieSettingCookie[]) => {
    const cookieConsentArgs = settings.reduce((acc, el) => {
      acc[el.type] = el.enabled ? "granted" : "denied"
      return acc
    }, {} as CookieConsentPermissions)
    updateCookieConsentMode(cookieConsentArgs)
  }

  // Saving cookie settings
  const saveCookieSettings = async (settings: CookieSettingCookie[] | null) => {
    if (!settings) {
      return
    }
    const settingsAction = updateCookieSettings.bind(
      null,
      locale as Locale,
      settings,
    )
    await settingsAction()
    handleChangeCookieDialog(false)
  }

  // Load cookie settings from cookies when component mounts
  useEffect(() => {
    if (cookies && cookies?.length > 0) {
      setCookieSettings(cookies)
      updateConsentMode(cookies)
    } else {
      handleChangeCookieDialog(true)
    }
  }, [cookies])

  const value: CookieContextType = {
    cookieSettings,
    cookieDialogOpen,
    handleChangeCookieDialog,
    saveCookieSettings,
  }

  return (
    <CookieContext.Provider value={value}>{children}</CookieContext.Provider>
  )
}
