import type { Shadows, ThemeOptions } from "@mui/material/styles"

// import { PrimaryFont, SecondaryFont } from "./font"

export const lightTheme = {
  breakpoints: {
    values: {
      xs: 375,
      sm: 480,
      md: 960,
      lg: 1280,
      xl: 1440,
    },
  },
  // typography: {
  //   fontFamily: SecondaryFont.style.fontFamily,
  //   h1: {
  //     fontFamily: PrimaryFont.style.fontFamily,
  //   },
  //   h2: {
  //     fontFamily: PrimaryFont.style.fontFamily,
  //   },
  //   h3: {
  //     fontFamily: PrimaryFont.style.fontFamily,
  //   },
  //   h4: {
  //     fontFamily: PrimaryFont.style.fontFamily,
  //   },
  //   h5: {
  //     fontFamily: PrimaryFont.style.fontFamily,
  //   },
  //   h6: {
  //     fontFamily: PrimaryFont.style.fontFamily,
  //   },
  // },
  spacing: 16,
  palette: {
    mode: "light",
    inherit: {
      main: "#4d4d4d",
      contrastText: "#fff",
    },
    primary: {
      50: "#E5EBFF",
      100: "#C9D8FF",
      200: "#ACC5FF",
      300: "#8CB3FF",
      400: "#64A1FF",
      500: "#1E90FF",
      600: "#2676d0",
      700: "#275ea2",
      800: "#244677",
      900: "#1e304f",
      main: "#1E90FF",
      contrastText: "#fff",
    },
    secondary: {
      50: "#E6FBFF",
      100: "#CCF7FF",
      200: "#AFF3FF",
      300: "#8EEFFF",
      400: "#64EBFF",
      500: "#00E6FF",
      600: "#1dbcd0",
      700: "#2393a2",
      800: "#236c77",
      900: "#1e484e",
      main: "#00E6FF",
      contrastText: "#fff",
    },
    grey: {
      50: "#F5F5F5",
      100: "#BCBCBC",
      200: "#9C9C9C",
      300: "#7D7D7D",
      400: "#606060",
      500: "#4D4D4D",
      600: "#393939",
      700: "#2F2F2F",
      800: "#242424",
      900: "#121212",
    },
  },
  gradiant: {
    inherit: `linear-gradient(112deg, #F5F5F5 0%, #BCBCBC 100%)`,
    primary: `linear-gradient(112deg, #1E90FF 0%, #00E6FF 100%)`,
    secondary: `linear-gradient(112deg, #00E6FF 0%, #1E90FF 100%)`,
    info: "linear-gradient(#BCBCBC, #9C9C9C, #7D7D7D)",
    success: "linear-gradient(#20E381, #20E381, #10CCB8)",
    warning: `linear-gradient(#FFCC00, #cca300)`,
    error: "linear-gradient(#eb5550, #db4a46, #ff4444)",
  },
  shadows: [
    "none",
    "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);",
    "0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)",
    "0px 2px 2px 0px rgba(0, 0, 0, 0.30),0px 6px 6px 0px rgba(0, 0, 0, 0.15)",
    ...Array(21).fill("none"),
  ] as Shadows,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Open Sans';
          font-style: normal;
          font-display: latin;
          font-weight: 400;
        }
      `,
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: "#dddddd",
        },
      },
    },
  },
} as ThemeOptions

export const darkTheme = {
  ...lightTheme,
  palette: {
    ...lightTheme.palette,
    mode: "dark",
    inherit: {
      main: "#fff",
      contrastText: "#4d4d4d",
    },
    primary: {
      50: "#1e304f", // Inverted from light theme
      100: "#244677",
      200: "#275ea2",
      300: "#2676d0",
      400: "#1E90FF",
      500: "#64A1FF",
      600: "#8CB3FF",
      700: "#ACC5FF",
      800: "#C9D8FF",
      900: "#E5EBFF",
      main: "#64A1FF",
    },
    secondary: {
      50: "#1e484e", // Inverted from light theme
      100: "#236c77",
      200: "#2393a2",
      300: "#1dbcd0",
      400: "#00E6FF",
      500: "#64EBFF",
      600: "#8EEFFF",
      700: "#AFF3FF",
      800: "#CCF7FF",
      900: "#E6FBFF",
      main: "#64EBFF",
    },
    grey: {
      50: "#121212", // Inverted from light theme
      100: "#242424",
      200: "#2F2F2F",
      300: "#393939",
      400: "#4D4D4D",
      500: "#606060",
      600: "#7D7D7D",
      700: "#9C9C9C",
      800: "#BCBCBC",
      900: "#F5F5F5",
    },
  },
  shadows: [
    "none",
    "0px 1px 2px 0px rgba(255, 255, 255, 0.30), 0px 1px 3px 1px rgba(255, 255, 255, 0.15);",
    "0px 1px 2px 0px rgba(255, 255, 255, 0.30), 0px 2px 6px 2px rgba(255, 255, 255, 0.15)",
    "0px 2px 2px 0px rgba(255, 255, 255, 0.30),0px 6px 6px 0px rgba(255, 255, 255, 0.15)",
    ...Array(21).fill("none"),
  ] as Shadows,
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Open Sans';
          font-style: normal;
          font-display: latin;
          font-weight: 400;
        }
      `,
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: "#7a7979",
        },
      },
    },
  },
} as ThemeOptions
