"use client"
import { type FC, useEffect, useRef, useMemo } from "react"

import type { LeadFormTranslations } from "./LeadForm"
import styles from "./LeadFormHeader.module.scss"

import { Headline } from "@/components/common/headline"
import { Stepper, type StepperRef } from "@/components/common/stepper"
import { create } from "@/helpers/bem"
import { useLeadForm } from "@/store/leadForm"
import { LEAD_FORM_STEPS } from "@/utils/leadForm"

const bem = create(styles, "LeadFormHeader")

export type LeadFormHeaderProps = {
  translations?: LeadFormTranslations
}

export const LeadFormHeader: FC<LeadFormHeaderProps> = ({ translations }) => {
  const { activeStep, activeArea, updateLeadForm } = useLeadForm()
  const ref = useRef<StepperRef>(null)

  const steps = useMemo(
    () =>
      LEAD_FORM_STEPS.map((key) => ({
        key,
        label:
          key === "solution" && activeArea
            ? translations?.services.leadForm?.areas?.[activeArea]?.label
            : translations?.services.leadForm?.steps[key].title,
      })),
    [],
  )

  useEffect(() => {
    ref.current?.jumpToStep(steps.findIndex((e) => e.key === activeStep))
  }, [activeStep])

  return (
    <div className={bem()}>
      <Stepper
        ref={ref}
        accessibilityTranslations={translations?.accessibility.stepper}
        activeStep={steps.findIndex((e) => e.key === activeStep)}
        steps={steps}
        onChangeStep={(index) =>
          updateLeadForm({
            activeStep: steps[index]?.key,
          })
        }
      />
      <div className={bem("content")}>
        <Headline
          key={activeStep}
          animated
          highlight
          align="center"
          className={bem("title")}
          size="md"
          type="h3"
          variant="inherit"
        >
          {translations?.services.leadForm?.steps?.[activeStep ?? ""]?.title}
        </Headline>
      </div>
    </div>
  )
}
