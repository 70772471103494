"use client"
import { useTheme } from "@mui/material/styles"
import { type FC, useCallback, useEffect, useRef } from "react"

import styles from "./LeadForm.module.scss"

import { LeadFormCompany } from "./LeadFormCompany"
import { LeadFormHeader } from "./LeadFormHeader"
import { LeadFormSolutions } from "./LeadFormSolutions"

import { Animated } from "@/components/common/animated"
import { Button } from "@/components/common/button"
import { Dialog, type DialogRef } from "@/components/common/dialog"

import { create } from "@/helpers/bem"
import { useLeadForm } from "@/store/leadForm"
import { LEAD_FORM_STEPS } from "@/utils/leadForm"
import type { CommonTranslations } from "@/utils/translations"

const bem = create(styles, "LeadForm")

export type LeadFormTranslations = CommonTranslations

export type LeadFormProps = {
  translations?: LeadFormTranslations
}

export const LeadForm: FC<LeadFormProps> = ({ translations }) => {
  const theme = useTheme()
  const dialogRef = useRef<DialogRef | null>(null)
  const { open, activeStep, updateLeadForm } = useLeadForm()

  useEffect(() => {
    const ref = dialogRef.current
    if (open) ref?.openDialog()
  }, [open])

  const renderStep = useCallback(() => {
    const t = translations?.services.leadForm
    switch (activeStep) {
      case "solution":
        return <LeadFormSolutions translations={t} />
      case "company":
        return <LeadFormCompany translations={t} />
      default:
        return null
    }
  }, [activeStep, translations])

  const handleBackClick = useCallback(() => {
    const currentStepIndex = LEAD_FORM_STEPS.findIndex(
      (el) => el === activeStep,
    )
    updateLeadForm({
      activeStep: LEAD_FORM_STEPS[currentStepIndex - 1],
    })
  }, [activeStep])

  return (
    <div className={bem()}>
      <Dialog
        ref={dialogRef}
        fullScreen
        hideTitle
        showCloseButton
        accessibilityTranslations={translations?.accessibility.dialog}
        scroll="paper"
        title="Lead-Form"
        translations={translations?.components.common.dialog}
        renderHeader={() =>
          activeStep !== "solution" && (
            <div
              className={bem("back__button")}
              role="button"
              tabIndex={0}
              aria-label={
                translations?.services.leadForm.accessibility.backButton
                  .ariaLabel
              }
              onClick={handleBackClick}
              onKeyDown={(e) =>
                e.code === "Enter" ? handleBackClick() : undefined
              }
            >
              <Button
                inert
                tabIndex={-1}
                iconProps={{
                  name: "LinkBackwardIcon",
                  size: "sm",
                  color: theme.palette.common.white,
                }}
              />
              <span className={bem("back__button__label")}>
                {
                  translations?.services.leadForm?.accessibility.backButton
                    .label
                }
              </span>
            </div>
          )
        }
        onClose={() =>
          updateLeadForm({
            open: false,
          })
        }
      >
        <LeadFormHeader translations={translations} />
        <Animated animation="right-left">{renderStep()}</Animated>
      </Dialog>
    </div>
  )
}
