"use client"
import { useTheme } from "@mui/material/styles"
import { type FC, useState, useCallback } from "react"

import type { LeadFormTranslations } from "./LeadForm"
import styles from "./LeadFormCompany.module.scss"

import { Card } from "@/components/common/card"
import { Grid, GridRow } from "@/components/common/grid"
import { Headline } from "@/components/common/headline"
import { Icon } from "@/components/common/icon"

import { create } from "@/helpers/bem"
import { useLeadForm } from "@/store/leadForm"
import {
  type LeadFormCompanySizeKey,
  LEAD_FORM_COMPANY_SIZE,
} from "@/utils/leadForm"

const bem = create(styles, "LeadFormCompany")

export type LeadFormCompany = {
  translations?: LeadFormTranslations["services"]["leadForm"]
}

export const LeadFormCompany: FC<LeadFormCompany> = ({ translations }) => {
  const theme = useTheme()
  const { updateLeadForm } = useLeadForm()
  const [hoveredIndex, setHoveredIndex] = useState<number>(-1)
  const onSelect = useCallback(
    (key: LeadFormCompanySizeKey) =>
      updateLeadForm({
        activeStep: "details",
        companySize: key,
      }),
    [],
  )
  const handleMouseEnter = useCallback(
    (index: number) => setHoveredIndex(index),
    [],
  )
  const handleMouseLeave = useCallback(() => setHoveredIndex(-1), [])
  return (
    <div className={bem()}>
      {LEAD_FORM_COMPANY_SIZE && LEAD_FORM_COMPANY_SIZE?.length > 0 && (
        <Grid className={bem("grid")}>
          {LEAD_FORM_COMPANY_SIZE.map((company, i) => {
            const key = company.key
            const isActive = hoveredIndex === i
            return (
              <GridRow
                key={`solution-${key}`}
                className={bem("grid__item", { "is-active": i < hoveredIndex })}
                lg={3}
                md={6}
                xs={12}
                onClick={() => onSelect(key)}
                onMouseEnter={() => handleMouseEnter(i)}
                onMouseLeave={handleMouseLeave}
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    onSelect(key)
                  }
                }}
              >
                <Card
                  className={bem("content")}
                  highlight={isActive}
                  onClick={() => onSelect(key)}
                  onKeyDown={(e) => {
                    if (e.code === "Enter") {
                      onSelect(key)
                    }
                  }}
                >
                  <div className={bem("icon")}>
                    <Icon
                      color={theme.palette.grey[500]}
                      name={company.icon}
                      size="lg"
                    />
                  </div>
                  <Headline
                    align="center"
                    className={bem("title")}
                    size="sm"
                    type="h3"
                    variant="inherit"
                  >
                    {translations?.steps.company.items[key].title}
                  </Headline>
                  <p className={bem("description")}>
                    {translations?.steps.company.items[key].description}
                  </p>
                </Card>
              </GridRow>
            )
          })}
        </Grid>
      )}
    </div>
  )
}
