import { gql } from "graphql-request"

export const GET_TREE = gql`
  query Tree {
    pages {
      data {
        attributes {
          slug
          locale
          updatedAt
        }
      }
    }
    categories {
      data {
        attributes {
          locale
          slug
        }
      }
    }
    articles {
      data {
        attributes {
          slug
          locale
          category {
            data {
              attributes {
                slug
              }
            }
          }
          updatedAt
        }
      }
    }
  }
`

export const GET_GLOBAL = gql`
  query Global($locale: I18NLocaleCode = "en") {
    i18NLocales {
      data {
        attributes {
          name
          code
        }
      }
    }
    global(locale: $locale) {
      data {
        attributes {
          metadata {
            metaTitle
            metaDescription
          }
          tracking {
            google {
              GTMId
            }
          }
          company {
            name
            legalName
            description
          }
          companyContact {
            contactType
            email
            phone
            openingHours
          }
          companyAddress {
            street
            city
            postalCode
            country
          }
          logo {
            logo {
              data {
                attributes {
                  alternativeText
                  url
                }
              }
            }
            logoDark {
              data {
                attributes {
                  alternativeText
                  url
                }
              }
            }
          }
          navbar {
            mobile {
              mobileDrawer {
                headline
              }
            }
            nav {
              id
              href
              target
              variant
              label
              sections {
                id
                title
                links {
                  id
                  href
                  target
                  description
                  variant
                  mobileVariant
                  label
                  icon
                }
              }
            }
            languageSwitchInputLabel
            callToAction {
              color
              variant
              label
              redirect {
                href
                target
                ariaLabel
              }
              icon
            }
          }
        }
      }
    }
  }
`

export const GET_COMPLEMENTARY_PAGE = gql`
  query ComplementaryPage($locale: String, $slug: String!) {
    pages(
      filters: {
        or: [
          { slug: { eq: $slug } }
          { localizations: { slug: { eq: $slug } } }
        ]
      }
    ) {
      data {
        id
        attributes {
          slug
          localizations(filters: { locale: { eq: $locale } }) {
            data {
              id
              attributes {
                locale
                slug
              }
            }
          }
        }
      }
    }
    articles(
      filters: {
        or: [
          { slug: { eq: $slug } }
          { localizations: { slug: { eq: $slug } } }
        ]
      }
    ) {
      data {
        id
        attributes {
          slug
          category {
            data {
              attributes {
                slug
              }
            }
          }
          localizations(filters: { locale: { eq: $locale } }) {
            data {
              id
              attributes {
                category {
                  data {
                    attributes {
                      slug
                    }
                  }
                }
                locale
                slug
              }
            }
          }
        }
      }
    }
    categories(
      filters: {
        or: [
          { slug: { eq: $slug } }
          { localizations: { slug: { eq: $slug } } }
        ]
      }
    ) {
      data {
        id
        attributes {
          slug
          localizations(filters: { locale: { eq: $locale } }) {
            data {
              id
              attributes {
                locale
                slug
              }
            }
          }
        }
      }
    }
  }
`

export const GET_GLOBAL_FOOTER = gql`
  query GlobalFooter($locale: I18NLocaleCode = "en") {
    global(locale: $locale) {
      data {
        attributes {
          footer {
            banner {
              variant
              headline
              description
              appStoreUrl
              playStoreUrl
              imageSrc {
                data {
                  attributes {
                    alternativeText
                    name
                    url
                  }
                }
              }
              imageSrcDark {
                data {
                  attributes {
                    alternativeText
                    name
                    url
                  }
                }
              }
            }
            newsletterForm {
              title
              titleOptions {
                type
                size
                variant
              }
              button {
                color
                variant
                label
                icon
              }
            }
            info {
              description
              copyright
              links {
                id
                href
                target
                variant
                label
              }
            }
            navLeft {
              headline
              links {
                id
                href
                target
                variant
                label
              }
            }
            navCenter {
              headline
              links {
                id
                href
                target
                variant
                label
              }
            }
            navRight {
              headline
              links {
                id
                href
                target
                variant
                label
              }
            }
            social {
              headline
              description
              links {
                id
                href
                target
                variant
                icon
              }
            }
          }
        }
      }
    }
  }
`

export const GET_LOGO = gql`
  query Logo($locale: I18NLocaleCode = "en") {
    global(locale: $locale) {
      data {
        attributes {
          logo {
            logo {
              data {
                attributes {
                  alternativeText
                  url
                }
              }
            }
            logoDark {
              data {
                attributes {
                  alternativeText
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_NAVBAR = gql`
  query Navbar($locale: I18NLocaleCode = "en") {
    i18NLocales {
      data {
        attributes {
          name
          code
        }
      }
    }
    global(locale: $locale) {
      data {
        attributes {
          navbar {
            mobile {
              mobileDrawer {
                headline
              }
            }
            nav {
              id
              href
              target
              variant
              label
              sections {
                id
                title
                links {
                  id
                  href
                  target
                  description
                  variant
                  mobileVariant
                  label
                  icon
                }
              }
            }
            navbarLogo {
              logo {
                data {
                  attributes {
                    alternativeText
                    name
                    url
                  }
                }
              }
              logoDark {
                data {
                  attributes {
                    alternativeText
                    name
                    url
                  }
                }
              }
            }
            languageSwitchInputLabel
            callToAction {
              color
              variant
              label
              redirect {
                href
                target
                ariaLabel
              }
              icon
            }
          }
        }
      }
    }
  }
`

export const GET_FOOTER = gql`
  query Footer($locale: I18NLocaleCode = "en") {
    global(locale: $locale) {
      data {
        attributes {
          footer {
            newsletterForm {
              title
              titleOptions {
                type
                size
                variant
              }
              button {
                color
                variant
                label
                icon
              }
            }
            logo {
              logo {
                data {
                  attributes {
                    alternativeText
                    name
                    url
                  }
                }
              }
              logoDark {
                data {
                  attributes {
                    alternativeText
                    name
                    url
                  }
                }
              }
            }
            info {
              description
              copyright
              links {
                id
                href
                target
                variant
                label
              }
            }
            navLeft {
              headline
              links {
                id
                href
                target
                variant
                label
              }
            }
            navCenter {
              headline
              links {
                id
                href
                target
                variant
                label
              }
            }
            navRight {
              headline
              links {
                id
                href
                target
                variant
                label
              }
            }
            social {
              headline
              description
              links {
                id
                href
                target
                variant
                icon
              }
            }
          }
        }
      }
    }
  }
`

export const GET_FOOTER_BANNER = gql`
  query FooterBanner($locale: I18NLocaleCode = "en") {
    global(locale: $locale) {
      data {
        attributes {
          footer {
            banner {
              variant
              headline
              description
              appStoreUrl
              playStoreUrl
              imageSrc {
                data {
                  attributes {
                    alternativeText
                    name
                    url
                  }
                }
              }
              imageSrcDark {
                data {
                  attributes {
                    alternativeText
                    name
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
