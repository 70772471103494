"use client"
import { type FC } from "react"

import type { LeadFormTranslations } from "./LeadForm"
import styles from "./LeadFormSolutions.module.scss"

import { Button } from "@/components/common/button"
import { Grid, GridRow } from "@/components/common/grid"
import { Headline } from "@/components/common/headline"
import { Lottie } from "@/components/common/lottie"

import { create } from "@/helpers/bem"
import { useLeadForm } from "@/store/leadForm"
import { LEAD_FORM_SOLUTIONS } from "@/utils/leadForm"

const bem = create(styles, "LeadFormSolutions")

export type LeadFormSolutionsProps = {
  translations?: LeadFormTranslations["services"]["leadForm"]
}

export const LeadFormSolutions: FC<LeadFormSolutionsProps> = ({
  translations,
}) => {
  const { activeArea, updateLeadForm } = useLeadForm()
  const solutions = LEAD_FORM_SOLUTIONS.find((e) => e.key === activeArea)?.items
  const buttonLabel = translations?.steps.solution.button ?? "Next step"
  return (
    <div className={bem()}>
      {solutions && solutions?.length > 0 && (
        <Grid className={bem("grid")}>
          {LEAD_FORM_SOLUTIONS.find((e) => e.key === activeArea)?.items.map(
            (solution) => {
              const key = solution.key
              const title = translations?.[key].title
              return (
                <GridRow
                  key={`solution-${key}`}
                  className={bem("grid__item")}
                  md={12 / solutions.length}
                  xs={12}
                >
                  <Lottie
                    animationName={solution?.animation}
                    containerClassName={bem("animation")}
                  />
                  <Headline
                    align="center"
                    className={bem("title")}
                    id={`solution-${key}`}
                    size="sm"
                    type="h3"
                    variant="inherit"
                  >
                    {title}
                  </Headline>
                  <p className={bem("description")}>
                    {translations?.[key].description}
                  </p>
                  <div className={bem("button")}>
                    <Button
                      aria-label={`${translations?.steps.solution.button} ${title}`}
                      title={buttonLabel}
                      onClick={() =>
                        updateLeadForm({
                          activeStep: "company",
                        })
                      }
                    />
                  </div>
                </GridRow>
              )
            },
          )}
        </Grid>
      )}
    </div>
  )
}
