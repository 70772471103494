"use client"
import dynamic from "next/dynamic"
import type { FC } from "react"

import type { ListDefaultItemProps } from "@/components/common/list"
import type {
  FooterNavigation,
  FooterInfoLink,
  FooterSocial,
  FooterSocialLink,
  FooterProps as CoreFooterProps,
} from "@/components/core/footer"

import { useGlobalFooter } from "@/helpers/api/client/global"
import { resolveMediaUrl } from "@/helpers/api-helpers"
import type {
  ComponentLayoutFooterInfo,
  ComponentLayoutFooterFormInput,
  ComponentLayoutFooterSocialInput,
  ComponentLinksLink,
  ComponentLayoutFooterLinks,
  ComponentLinksSocialLink,
  ComponentLayoutLogo,
  UploadFile,
} from "@/types/generated/strapi/graphql"
import type { Locale } from "@/utils/translations/i18n-config"
import type { Translations } from "@/utils/translations/locales/common.model"

const FooterContainer = dynamic(
  () =>
    import("./FooterContainer").then(({ FooterContainer }) => ({
      default: FooterContainer,
    })),
  { ssr: false },
)

export type FooterProps = {
  locale: Locale
  logo?: ComponentLayoutLogo
  info?: ComponentLayoutFooterInfo
  social?: ComponentLayoutFooterSocialInput
  translations?: Translations["pages"]["components"]["footer"]
}

export const Footer: FC<FooterProps> = ({ locale, logo, translations }) => {
  const { data } = useGlobalFooter(locale)
  const footer = data?.global?.data?.attributes?.footer
  const logoSrc = logo?.logo.data?.attributes as UploadFile
  const logoSrcDark = logo?.logoDark?.data?.attributes as UploadFile | undefined
  const defaultLogoAlt = logoSrc?.alternativeText
  const navigation = [footer?.navLeft, footer?.navCenter, footer?.navRight]
  const info = footer?.info
  const social = footer?.social
  const footerProps = {
    info: {
      logo: logoSrc
        ? {
            src: resolveMediaUrl(logoSrc.url as string),
            alt: defaultLogoAlt,
          }
        : undefined,
      logoDark: logoSrcDark
        ? {
            src: resolveMediaUrl(logoSrcDark?.url as string),
            alt: logoSrcDark?.alternativeText ?? defaultLogoAlt,
          }
        : undefined,
      copyright: info?.copyright,
      description: info?.description,
      links: (info?.links ?? []).map(
        (link) =>
          ({
            ...link,
            target: link?.target ? `_${link?.target}` : "_self",
            children: link?.label,
          }) as FooterInfoLink,
      ),
    } as unknown as CoreFooterProps,
    navigation:
      navigation &&
      navigation
        .filter((nav): nav is ComponentLayoutFooterLinks => Boolean(nav))
        .map(
          (nav: ComponentLayoutFooterLinks) =>
            ({
              ...nav,
              items:
                nav?.links &&
                (nav.links
                  .filter((link): link is ComponentLinksLink => link !== null)
                  .map((link: ComponentLinksLink) => ({
                    title: link?.label,
                    redirect: {
                      href: link?.href,
                      target: link?.target ? `_${link?.target}` : "_self",
                    },
                  })) as ListDefaultItemProps[]), // Use `map` directly for links transformation
            }) as FooterNavigation,
        ),
    social: {
      ...social,
      links:
        social?.links &&
        social?.links?.length > 0 &&
        social.links
          .filter((link): link is ComponentLinksSocialLink => link !== null)
          .map(
            (link: ComponentLinksSocialLink) =>
              ({
                key: link?.id,
                icon: link.icon,
                variant: link.variant,
                redirect: {
                  href: link?.href,
                  target: link?.target ? `_${link?.target}` : "_blank",
                },
              }) as FooterSocialLink,
          ),
    } as FooterSocial,
  } as unknown as CoreFooterProps
  return (
    <FooterContainer
      {...footerProps}
      form={footer?.newsletterForm as ComponentLayoutFooterFormInput}
      translations={translations}
    />
  )
}
