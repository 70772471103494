"use client"
import MUIDialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { useTheme } from "@mui/material/styles"
import {
  type FC,
  type KeyboardEvent,
  useState,
  useImperativeHandle,
  useCallback,
} from "react"

import type { DialogProps } from "./Dialog.model"
import styles from "./Dialog.module.scss"

import { Button } from "@/components/common/button"

import { create } from "@/helpers/bem"

const bem = create(styles, "Dialog")

export const Dialog: FC<DialogProps> = ({
  ref,
  open,
  title,
  titleProps = {},
  hideTitle,
  showCloseButton,
  contentProps = {},
  actions,
  renderHeader,
  onClose,
  children,
  accessibilityTranslations,
  translations,
  ...props
}) => {
  const theme = useTheme()
  const [dialogOpen, setDialogOpen] = useState<boolean>(open ?? false)

  const handleClose = useCallback(() => {
    setDialogOpen(false)
    if (onClose) {
      onClose({}, "backdropClick")
    }
  }, [])

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.code === "Enter") {
      handleClose()
    }
  }, [])

  useImperativeHandle(
    ref,
    () => ({
      openDialog: () => setDialogOpen(true),
      closeDialog: handleClose,
    }),
    [handleClose],
  )

  return (
    <MUIDialog
      {...props}
      aria-labelledby="dialog-title"
      open={dialogOpen}
      classes={{
        ...props?.classes,
        paper: bem(undefined, undefined, props?.classes?.root),
      }}
      onClose={handleClose}
    >
      <div className={bem("container")}>
        <div className={bem("header")}>
          <div>
            <DialogTitle
              {...titleProps}
              id="dialog-title"
              className={bem("title", {
                "is-hidden": !!hideTitle,
              })}
            >
              {title}
            </DialogTitle>
            {renderHeader?.()}
          </div>
          {showCloseButton && (
            <div
              className={bem("close")}
              role="button"
              tabIndex={0}
              aria-label={
                (accessibilityTranslations?.close ?? "Close dialog") as string
              }
              onClick={handleClose}
              onKeyDown={handleKeyDown}
            >
              <span className={bem("close__label")}>
                {translations?.cancel ?? "Cancel"}
              </span>
              <Button
                inert
                color="info"
                tabIndex={-1}
                variant="text"
                iconProps={{
                  name: "Cancel01Icon",
                  size: "sm",
                  color: theme.palette.grey[300],
                }}
              />
            </div>
          )}
        </div>
        <DialogContent
          {...contentProps}
          classes={{
            ...contentProps?.classes,
            root: bem("content", undefined, contentProps?.classes?.root),
          }}
        >
          {children}
        </DialogContent>
        {actions && actions.length > 0 && (
          <DialogActions>
            {actions.map((action, i) => (
              <Button key={`dialog-action-button-${i}`} {...action} />
            ))}
          </DialogActions>
        )}
      </div>
    </MUIDialog>
  )
}

Dialog.displayName = "Dialog"
