"use client"
import { useGraphQL } from "./lib/useGraphQL"

import { GET_GLOBAL_FOOTER } from "@/queries/global"

import type { GlobalFooterQuery } from "@/types/generated/strapi/graphql"
import { REVALIDATION_TAGS } from "@/utils/enviroment"

import type { Locale } from "@/utils/translations/i18n-config"

export const useGlobalFooter = (locale: Locale) =>
  useGraphQL<GlobalFooterQuery>(
    GET_GLOBAL_FOOTER,
    { locale },
    {
      ...context,
      fetchOptions: {
        next: { tags: REVALIDATION_TAGS.global },
      },
    },
  )
