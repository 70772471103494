import { useTheme } from "@mui/material/styles"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import { type FC, type ReactEventHandler, useCallback } from "react"

import { Button } from "../button"
import { Headline } from "../headline"

import type { DrawerProps } from "./Drawer.model"
import styles from "./Drawer.module.scss"

import { create } from "@/helpers/bem"

const bem = create(styles, "Drawer")

export const renderDrawerTitle = (title?: string) =>
  title && (
    <Headline size="md" type="h2">
      {title}
    </Headline>
  )

export const renderDrawerCloseButton = (onClose: ReactEventHandler<{}>) => (
  <Button
    aria-label="Close dialog"
    color="info"
    role="button"
    variant="text"
    iconProps={{
      name: "Cancel01Icon",
      size: "sm",
    }}
    onClick={onClose}
  />
)

export const Drawer: FC<DrawerProps> = ({
  title,
  children,
  anchor,
  disabled,
  PaperProps = {
    sx: {},
  },
  renderHeader,
  ...props
}) => {
  const theme = useTheme()
  const isDark = theme.palette.mode === "dark"
  const spacing = theme.spacing(1)
  let borderRadius = {}
  let width = "100%"
  let height = "80%"
  switch (anchor) {
    case "bottom":
      borderRadius = {
        borderTopLeftRadius: spacing,
        borderTopRightRadius: spacing,
      }
      break
    case "top":
      borderRadius = {
        borderBottomLeftRadius: spacing,
        borderBottomRightRadius: spacing,
      }
      break
    case "left":
      width = "80%"
      height = "100%"
      borderRadius = {
        borderTopRightRadius: spacing,
        borderBottomRightRadius: spacing,
      }
      break
    case "right":
      width = "80%"
      height = "100%"
      borderRadius = {
        borderTopLeftRadius: spacing,
        borderBottomLeftRadius: spacing,
      }
      break
    default:
      break
  }
  const paperSx = {
    width,
    height,
    padding: spacing,
    color: theme.palette.inherit?.main,
    backgroundColor: isDark
      ? theme.palette.common?.black
      : theme.palette.common.white,
    ...borderRadius,
  }

  const renderCustomHeader = useCallback(() => {
    if (!renderHeader?.()) {
      return (
        <div
          className={bem("header", {
            "has-title": !!title,
          })}
        >
          {renderDrawerTitle(title)}
          {!disabled && renderDrawerCloseButton(props.onClose)}
        </div>
      )
    }
    return renderHeader()
  }, [title, disabled, props.onClose, renderHeader])
  return (
    <SwipeableDrawer
      disableBackdropTransition
      anchor={anchor}
      aria-labelledby={title}
      disableEscapeKeyDown={disabled}
      sx={{
        padding: spacing,
      }}
      {...props}
      PaperProps={{
        ...PaperProps,
        sx: {
          ...paperSx,
          ...PaperProps?.sx,
        },
      }}
    >
      {renderCustomHeader()}
      <div className={bem("content")}>{children}</div>
    </SwipeableDrawer>
  )
}
