"use client"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useCallback, useState, memo } from "react"

import { Drawer } from "../../common/drawer"
import { Grid } from "../../common/grid"

import type {
  CookieBannerProps,
  CookieBannerActionButton,
} from "./CookieBanner.model"

import { CookieBannerButtons } from "./CookieBannerButtons"
import { CookieBannerContent } from "./CookieBannerContent"
import { CookieBannerSettings } from "./CookieBannerSettings"

export const CookieBanner = memo(
  ({
    variant = "primary",
    content,
    settings,
    acceptButton,
    settingsButton,
    rejectButton,
    onClose,
    ...props
  }: CookieBannerProps) => {
    const theme = useTheme()
    const screenSmallerMd = useMediaQuery(theme.breakpoints.down("md"))
    const [settingsOpen, setSettingsOpen] = useState(false)
    const handleChangeDialog = useCallback(() => {
      setSettingsOpen((prevState) => !prevState)
    }, [])
    return (
      <>
        <Drawer
          disabled
          anchor="bottom"
          renderHeader={() => <></>}
          ModalProps={{
            disableScrollLock: true,
            sx: {
              "& .MuiBackdrop-root": {
                backgroundColor: "rgba(0, 0, 0, 0.3)",
              },
            },
          }}
          PaperProps={{
            sx: {
              height: "auto",
              backgroundColor: theme.palette.common.white,
            },
          }}
          sx={{
            padding: theme.spacing(2),
            "& .MuiPaper-root:before": {
              backgroundColor: "red",
            },
          }}
          onOpen={() => undefined}
          {...props}
          content=""
          onClose={() => undefined}
        >
          <Grid spacing={1}>
            <CookieBannerContent content={content} />
            <CookieBannerButtons
              acceptButton={acceptButton}
              rejectButton={rejectButton}
              screenSmallerMd={screenSmallerMd}
              setSettingsOpen={setSettingsOpen}
              settingsButton={settingsButton}
              onClose={onClose}
            />
          </Grid>
        </Drawer>
        <CookieBannerSettings
          {...settings}
          open={settingsOpen}
          acceptButton={
            {
              ...acceptButton,
              ...settings.acceptButton,
            } as CookieBannerActionButton
          }
          rejectButton={
            {
              ...rejectButton,
              ...settings.rejectButton,
            } as CookieBannerActionButton
          }
          settingsButton={
            {
              ...settingsButton,
              ...settings.settingsButton,
            } as CookieBannerActionButton
          }
          onOpen={() => undefined}
          onClose={(e) => {
            handleChangeDialog()
            settings?.onClose?.(e)
          }}
        />
      </>
    )
  },
)
