import MuiSkeleton, {
  type SkeletonProps as MuiSkeletonProps,
} from "@mui/material/Skeleton"
import type { FC } from "react"

export type SkeletonProps = MuiSkeletonProps

export const Skeleton: FC<SkeletonProps> = (props) => (
  <MuiSkeleton height="100%" variant="rounded" width="100%" {...props} />
)
