"use client"
import Step from "@mui/material/Step"
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector"
import StepLabel from "@mui/material/StepLabel"
import MUIStepper from "@mui/material/Stepper"
import { styled } from "@mui/material/styles"
import { type FC, useState, useImperativeHandle } from "react"

import type { StepperProps } from "./Stepper.model"
import styles from "./Stepper.module.scss"

import { Icon } from "@/components/common/icon"

import { create } from "@/helpers/bem"

const bem = create(styles, "Stepper")

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
    left: "calc(-50% + 50px)",
    right: "calc(50% + 50px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      opacity: 1,
      backgroundImage: theme.gradiant.secondary,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      opacity: 1,
      backgroundImage: theme.gradiant.secondary,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundImage: theme.gradiant.info,
    opacity: 0.5,
  },
}))

export const Stepper: FC<StepperProps> = ({
  ref,
  steps,
  onChangeStep,
  accessibilityTranslations,
  ...props
}) => {
  const [activeStep, setActiveStep] = useState<number>(1)

  useImperativeHandle(
    ref,
    () => ({
      jumpToStep: (index: number) => setActiveStep(index),
    }),
    [],
  )

  return (
    <MUIStepper
      alternativeLabel
      connector={<ColorlibConnector />}
      {...props}
      activeStep={activeStep}
    >
      {steps.map((step, i) => {
        const label = step?.label
        const active = i === activeStep
        const completed = activeStep > i
        return (
          <Step key={label ?? `step-${i}`} {...step}>
            {label && (
              <StepLabel
                {...step?.labelProps}
                classes={{
                  iconContainer: bem(
                    "icon",
                    {
                      "is-active": active,
                    },
                    step?.labelProps?.classes?.iconContainer,
                  ),
                  label: bem(
                    "label",
                    {
                      "is-active": active || completed,
                    },
                    step?.labelProps?.classes?.label,
                  ),
                }}
                icon={
                  <div
                    aria-label={`Step ${i + 1} ${label}. ${accessibilityTranslations?.status.ariaLabel} ${completed ? accessibilityTranslations?.status.completed.ariaLabel : accessibilityTranslations?.status.open.ariaLabel}`}
                    tabIndex={0}
                    className={bem("icon__container", {
                      "is-active": active || completed,
                    })}
                    onClick={() => {
                      if (activeStep !== i && activeStep > i) {
                        setActiveStep(i)
                        onChangeStep?.(i)
                      }
                    }}
                  >
                    <span
                      className={bem("icon__label", {
                        "is-active": active || completed,
                      })}
                    >
                      {completed ? (
                        <div className={bem("icon__completed__container")}>
                          <Icon
                            className={bem("icon__completed")}
                            name="Tick01Icon"
                            size="md"
                          />
                        </div>
                      ) : (
                        i + 1
                      )}
                    </span>
                  </div>
                }
              >
                {label}
              </StepLabel>
            )}
          </Step>
        )
      })}
    </MUIStepper>
  )
}
