"use client"
import { type ReactNode, memo } from "react"

import { GridRow } from "../../common/grid"

import styles from "./CBContent.module.scss"

import { create } from "@/helpers/bem"

const bem = create(styles, "CBContent")

export const CookieBannerContent = memo(
  ({ content }: { content: () => ReactNode }) => (
    <GridRow lg={8} md={7} xs={12}>
      <p className={bem()}>{content()}</p>
    </GridRow>
  ),
)
